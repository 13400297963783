<template>
  <v-alert
    :elevation="1"
    colored-border
    border="bottom"
    :color="message.userColor"
    :class="{ message: true, own: own, 'pa-0': true, 'msg--caret': true }"
  >
    <v-menu offset-y offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="menu-btn" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-if=" $root.acceso('DEV') || (own && new Date().getTime() <= Number(message.fecha) + 1000 * 60 * 10) "
              :disabled="deleting"
              @click.stop="eliminar"
            >
              <v-list-item-icon>
                <v-icon color="error">mdi-delete</v-icon>
              </v-list-item-icon>
              Eliminar
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>

    <v-card-subtitle class="py-2 px-1 d-inline-block">
      <span
        :style="`color: ${message.userColor}`"
        class="font-weight-bold mx-2"
        >{{ message.Nombre }}</span
      >
      <v-icon small color="primary" v-if="own">mdi-account</v-icon>
      {{ new Date(Number(message.fecha)).toLocaleString() }}
      <!-- <status-chip class="ml-1" x-small :value="message.tipo" v-if="message.tipo" /> -->
      <status-chip class="ml-1" small :value="message.tipo" v-if="message.tipo" />
      <v-icon v-if="Date.now() <= (Number(message.fecha) + (1000 * 3600))">mdi-circle-small</v-icon>
    </v-card-subtitle>

    <v-divider />

    <div v-if="message.mensaje" class="pa-4 msg--text" v-html="message.mensaje" />

    <div class="d-grid" v-if="message.children.length > 0">
      <template v-for="child in message.children" >
        <img
          v-if=" ['jpg', 'png', 'gif', 'jpeg', 'SVG', 'WebP'].includes( child.extension ) "
          :src="`https://impagados.alumbraenergia.es/impDocs/${child.archivo}`"
          class="msg--img pa-1"
        />
        <v-chip
          v-else
          label
          @click="descargarArchivo(child.archivo)"
          color="primary"
          outlined
          class="ma-1 mt-0"
        >
          <v-icon left>mdi-download</v-icon>
          {{ child.archivo.substring(child.archivo.indexOf("_") + 1) }}
        </v-chip>
      </template>
    </div>

  </v-alert>
</template>

<script>
import { parseDate, downloadFile } from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
  props: {
    message: {
      type: Object,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
  },
  computed: {
    own() {
      return (
        this.$store.getters.getDecodedJwtEmpresa.idUsuario == this.message.idUsuario
      );
    },
  },
  methods: {
    parseDate,
    descargarArchivo(name) {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
        responseType: "blob",
        params: {
          name,
          token: this.$store.getters.getJwtEmpresa
        },
      }).then((res) => {
        let extension = name
          .split(".")
          [name.split(".").length - 1].toLowerCase();

        let file = new File([res.data], name.substring(name.indexOf("_") + 1), {
          type: extensions[extension],
        });

        let download = true;
        if (["pdf", "png", "jpg", "jpeg", "gif"].includes(extension))
          download = false;

        downloadFile(file, !download);
        this.downloadingFile = false;
        // window.open(fileURL);
      });
    },
    eliminar() {
      if (!confirm("¿Seguro que quieres eliminar este mensaje?")) return;
      this.deleting = true;
      axios({
        method: "delete",
        url: `${process.env.VUE_APP_OUR_API_URL}/mensajesImpagados.php`,
        data: {
          idMensaje: this.message.idMensaje,
          token: this.$store.getters.getJwtEmpresa
        },
      }).then((res) => {
        this.$emit("reload");
        this.deleting = false;
      });
    },
  },
};
</script>

<style lang="scss">
.message {
  align-self: start;
  min-width: 300px;
  max-width: calc(100% - 50px);
  .menu-btn {
    float: right;
  }
  margin-bottom: 3px;
  .v-alert__border {
    border-width: 1.25px;
  }
  .msg--img, img {
    display: inline-flex;
    width: 100%;
    // max-width: 300px;
  }
  .msg--text {
    code, code > a {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}
  }
}
.own {
  align-self: end;
  .menu-btn {
    float: left;
  }
  .msg--text {
    
  }
}
// .v-alert__content {
//   display: flex;
//   flex-direction: column;
// }

.d-grid {
  display: grid;
  // grid-gap: 10px;
}


</style>
